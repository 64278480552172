<template>
  <div class="flex justify-center">
    <div
      class="grid [grid-template-areas:'footerCompany_footerLibrary''footerNews1_footerCommunity'] px-5 py-5 md:[grid-template-areas:'footerCompany_footerNews1_footerLibrary_footerCommunity'] w-full lg:w-[1000px]"
    >
      <div class="flex flex-col [grid-area:footerCompany]">
        <FooterTitle :text="$t('footer.company')" />
        <!--<FooterLink :text="$t('footer.aboutUs')" link="#"/>-->
        <!--<FooterLink :text="$t('footer.press')" link="#"/>-->
        <!--<FooterLink :text="$t('footer.disclaimer')" link="#"/>-->
        <FooterLink :text="$t('footer.privacyPolicy')" link="/privacy-notice" />
        <FooterLink :text="$t('footer.termsOfUse')" link="/terms-of-use" />
        <FooterLink :text="$t('footer.eula')" link="/eula" />
        <FooterLink :text="$t('footer.contactUs')" link="mailto:cardanospot@emurgo.io" />
      </div>
<!--      <div class="flex flex-col [grid-area:footerLibrary]">-->
<!--        <FooterTitle :text="$t('footer.library')" />-->
<!--        <FooterLink :text="$t('footer.projectLibrary')" link="/project-library/all" />-->
<!--        &lt;!&ndash;<FooterLink  :text="$t('footer.submitProject')" link="#" />&ndash;&gt;-->
<!--        <FooterLink :text="$t('footer.promoteWithUs')" link="mailto:cardanospot@emurgo.io" />-->
<!--      </div>-->
<!--      <div class="flex flex-col [grid-area:footerCommunity]">-->
<!--        <FooterTitle :text="$t('footer.community')" />-->
<!--        <FooterLink :text="$t('footer.community')" link="/community-feed/all" />-->
<!--        <FooterLink :text="$t('footer.events')" link="/events" />-->
<!--        <FooterLink-->
<!--          :link="thereIsSession ? '/user/create-event' : null"-->
<!--          :text="$t('footer.submitEvent')"-->
<!--          class="cursor-pointer"-->
<!--          @click="() => handleGuestUser(GTM_EVENT_GUEST_SUBMIT_EVENT_FOOTER)"-->
<!--        />-->
<!--      </div>-->
      <div class="flex flex-col [grid-area:footerNews1]">
        <FooterTitle :text="$t('footer.news')" />
        <div class="grid grid-cols-1 lg:grid-cols-2">
          <template v-for="tag in tags">
            <FooterLink v-if="tag.showOnFooter" :link="'/news-feed/' + tag._id" :text="tag.name" />
          </template>
<!--          <FooterLink-->
<!--            :link="thereIsSession ? '/user/create-article' : null"-->
<!--            :text="$t('footer.submitArticle')"-->
<!--            class="cursor-pointer"-->
<!--            @click="() => handleGuestUser(GTM_EVENT_GUEST_SUBMIT_ARTICLE_FOOTER)"-->
<!--          />-->
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {useGetAllNewsTags} from '~/composables/news'
import {useIsUserInSession} from '~/composables/auth/useStateCSpotSession'
import {INTERNAL_EVENT_USER_NOT_REGISTERED} from '~/constants/internal'
import {
  GTM_EVENT_GUEST_SUBMIT_ARTICLE_FOOTER,
  GTM_EVENT_GUEST_SUBMIT_EVENT_FOOTER,
  GTM_EVENT_TYPE_CLICK
} from '~/constants/gtmEvent'

const config = useRuntimeConfig()
const {$eventEmitter, $eventTracker} = useNuxtApp()

const thereIsSession = useIsUserInSession()

const {data: tags} = await useGetAllNewsTags()

const handleGuestUser = (eventName: string | null) => {
  if (!thereIsSession.value) {
    if (eventName) {
      $eventTracker.triggerEvent(eventName, GTM_EVENT_TYPE_CLICK)
    }
    $eventEmitter(INTERNAL_EVENT_USER_NOT_REGISTERED)
  }
}
</script>
